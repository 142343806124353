@import "media-query";
@import "variables";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $bg_color;
}

a {
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

p {
  margin: 0;
}

select {
  //先将默认的select选择框样式清除
  appearance: none;
  outline: none;
  background-color: transparent;
}

input, textarea, pre{
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
  appearance: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;

  &:focus {
    border-color: $primary_color !important;
  }

  &::placeholder {
    color: $desc_color !important;
  }
}

.loading-animation {
  animation: loading-spin infinite 900ms linear;
  width: 40px;
}

@keyframes loading-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

[class^="icon-"], [class*=" icon-"] {
  display: inline-block;
  vertical-align: middle;
}

@include mobile {
  html, body {
    font-size: 14px;
  }
}

@include tablet {
  html, body {
    font-size: 14px;
  }
}

@include desktop {
  html, body {
    font-size: 16px;
  }
}

.page-loading-wrap {
  border-radius: 5px;
  background-color: $fg_color;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 10px;
  height: 72vh;
  @include desktop {
    margin-top: 15px;
    height: 80vh;
  }

  .no-tips {
    color: $primary_color;
    font-size: 14px;
    @include desktop {
      font-size: 18px;
    }
  }
}

.loading-icon {
  $size: 30px;
  width: $size;
  height: $size;
  border-radius: $size / 2;
  border-width: 3px;
  border-style: solid;
  border-color: $primary_color;
  border-left-color: transparent;
  box-sizing: border-box;
}

.flag {
  color: #ec5b37;
}

ul, li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
  margin-top: 20px;
  @include desktop {
    margin-top: 50px;
  }

  .icon {
    color: $title_color;
  }

  .page-item {
    margin: 0 5px;
    border-radius: 5px;

    &:hover {
      background-color: rgba($primary_color, .2);
    }
  }

  .page-link {
    display: inline-block;
    text-align: center;
    font-size: 13px;
    cursor: pointer;
    user-select: none;
    color: rgba($title_color, .7);

    line-height: 32px;
    min-width: 32px;
    @include desktop {
      font-size: 16px;
      line-height: 46px;
      min-width: 46px;
    }
  }

  .active {
    background-color: $primary_color;

    .page-link {
      color: #fff;
    }
  }

  .disable-link > a {
    color: red;
    display: none;
  }
}


.fade {
  animation: fade-spin .3s ease;
}

@keyframes fade-spin {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
