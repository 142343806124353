@import "variables";
@import "media-query";

.search {
  display: flex;
  flex-direction: column;
  @include content_width;

  padding: 10px 0;
  @include desktop {
    padding: 20px 0;
  }
}

.main {
  display: flex;
  flex-direction: row;
  align-items: start;

  .main-left {
    flex: 1 1 calc(17 / 24 * 100%);
  }

  .main-right {
    background-color: $fg_color;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    position: fixed;
    inset: 0;
    top: 51px;
    z-index: 10;

    @include tablet {
      top: 65px;
      position: sticky;
      width: 0; //防止内容被文字撑开
      margin-left: 20px;
      flex: 1 1 calc(7 / 24 * 100%);
    }
    @include desktop {
      top: 75px;
    }

    .icon {
      padding: 10px;
    }
  }
}

.page-btn-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  user-select: none;
  position: relative;

  .refresh-records {
    position: absolute;
    right: 6px;
    cursor: pointer;

    &:active {
      transform: scale(.8);
      transition: transform .2s;
    }
  }

}

.record-wrap {
  @include mobile {
    height: calc(100vh - 200px);
    overflow-y: auto;
  }

  .record-item-wrap {
    padding: 10px 0 10px 10px;
    border-top: 1px solid #f8f8f8;
    display: flex;
    flex-direction: column;
    //align-items: center;

    .icon {
      cursor: pointer;
      margin: 0 5px;
    }

    .record-item {
      display: flex;
      flex: 1;
      //flex-direction: column;
      align-self: stretch;
      @include fade;

      .keyword {
        flex: 1;
        color: $title_color;
        font-size: 15px;
        display: -webkit-box;
        word-break: break-all;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        cursor: pointer;

      }
    }
  }
}

.st {
  //margin-left: 5px;
  font-size: 13px;
  color: #888;
  user-select: none;
}

.rt {
  font-size: 13px;
  color: #888;
  //color: $primary_color;
  user-select: none;
}

.find-wrap {
  display: flex;
  margin-top: 6px;
  font-size: 13px;

  a {
    margin-right: 8px;
    color: #5b75c5;
    cursor: pointer;

    //&:after {
    //  content: ' / ';
    //}

    &:hover {
      text-decoration: underline;
    }
  }
}

.time {
  font-size: 13px;
  color: #aaa;
  margin-top: 5px;
  user-select: none;
}

.type-wrap {
  display: flex;
  flex-direction: row;
  background-color: $fg_color;
  padding: 5px;
  border-radius: 5px;
  z-index: 9;
  border-top: 1px solid $bg_color;
  border-bottom: 1px solid $bg_color;
  position: sticky;

  top: 50px;
  @include desktop {
    top: 55px;
  }

  .type-item {
    flex: 1;
    cursor: pointer;
    user-select: none;
    color: $title_color;
    text-align: center;
    border-radius: 5px;
    -webkit-tap-highlight-color: transparent; //解决移动端点击跳闪
    transition: background-color .2s;

    font-size: 14px;
    padding: 8px 0;
    @include desktop {
      font-size: 16px;
      padding: 10px 0;
    }

    &.active {
      //background-color: $primary_color;
      color: #fff;
    }
  }

  .user-type, .buy-type {
    margin-left: 5px;

    &.active {
      background-color: #eb5254;
    }
  }

  .buy-type.active {
    background-color: #5271eb;
  }
}

