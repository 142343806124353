//$bg_color: #181d2e;
//$fg_color: #262d3d;
////$primary_color: #7560db;
////$primary_color_dark: #614fb6;
//$primary_color: #6A90FF;
//$primary_color_dark: #4f6dc6;
//$user_color: #7792c9;
//$title_color: #feffff;
//$desc_color: #c0c2d0;
//$time_color: #818092;

$bg_color: #f2f8fc;
$fg_color: #fff;
$primary_color: #6A90FF;
$primary_color_dark: #4f6dc6;
$user_color: #0a607c;
$title_color: #09090a;
$desc_color: #444;
$time_color: #a2a1aa;
$error_color: #de3d61;
$success_color: rgb(87, 181, 128);
$radius: 2px;
$v_gap: 10px;
$border_color: #fff;
$bottom_tab_height: 60px;