@import "../media-query";
@import "../variables";

.loading {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0002;
  user-select: none;
  pointer-events: auto;
  z-index: 9999;
  animation: fade-spin .2s linear;

  @keyframes fade-spin {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}