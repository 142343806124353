@import "../variables";
@import "../media-query";

.announcement {
  display: block;
  border-radius: 5px;
  background-color: $fg_color;
  overflow: hidden;
  cursor: pointer;

  margin-bottom: 10px;
  @include desktop {
    margin-bottom: 15px;
  }

  img {
    width: 100%;
    object-fit: cover;
    display: flex;

    max-height: 150px;
    @include desktop {
      max-height: 180px;
    }
  }

  .a-content {
    padding: 10px;
    @include desktop {
      padding: 13px;
    }

    .a-title {
      color: $title_color;
      //font-weight: 500;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      line-height: 1.5;

      font-size: 14px;
      -webkit-line-clamp: 4;
      @include desktop {
        font-size: 16px;
        -webkit-line-clamp: 5;
      }
    }

    .a-desc {
      color: $desc_color;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;

      margin-top: 5px;
      font-size: 13px;
      -webkit-line-clamp: 2;
      line-height: 1.4;
      @include desktop {
        margin-top: 7px;
        font-size: 15px;
        -webkit-line-clamp: 3;
        line-height: 1.6;
      }
    }
  }

  .button-wrap {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0 10px 10px;
    @include desktop {
      padding: 0 13px 13px;
    }

    .button {
      padding: 5px 10px;
      margin-left: 10px;
      @include desktop {
        padding: 8px 18px;
      }
    }
  }
}