$mobile: 600px;
$tablet: 960px;
$desktop: 1280px;

@mixin mobile {
  @media screen and (max-width: #{$mobile}) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: #{$mobile}) {
    //@media screen and (min-width: #{$mobile}) and (max-width: #{$desktop}) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: #{$desktop}) {
    @content;
  }
}

@mixin retina2 {
  @media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
    @content;
  }
}

@mixin retina3 {
  @media only screen and (-webkit-min-device-pixel-ratio: 3) and (min-resolution: 288dpi) {
    @content;
  }
}

@mixin landscape {
  @media screen and (orientation: landscape) {
    @content;
  }
}

@mixin portrait {
  @media screen and (orientation: portrait) {
    @content;
  }
}

@mixin content_width {
  box-sizing: border-box;
  margin: 0 auto;
  min-width: 300px;
  width: 95%;
  @include tablet {
    width: 90%;
  }
  @include desktop {
    width: 1200px;
  }
}


@mixin fade {
  animation: fade-spin .3s ease;
}

@keyframes fade-spin {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
