@import "../media-query";
@import "../variables";

.item {
  background: $fg_color;
  border-radius: 5px;
  position: relative;
  @include fade;

  padding: 5px 15px 15px;
  margin: 10px 0;
  @include desktop {
    padding: 6px 20px 20px;
    margin: 15px 0;
  }

  .item-header {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #fafafa;
    //justify-content: space-between;

    .delete, .edit {
      user-select: none;
      cursor: pointer;
      padding: 5px;
      color: #6A90FF;
      font-size: 14px;
      @include desktop {
        padding: 10px;
        font-size: 15px;
      }

      &:hover {
        text-decoration: underline;
      }
    }

    .delete {
      color: var(--toastify-color-error);
    }
  }

  .detail-title {
    position: relative;
    color: $title_color;
    font-weight: 500;
  }

  .title {
    position: relative;
    z-index: 2;
    color: $title_color;
    font-weight: 500;
    display: -webkit-box;
    word-break: break-all;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;

    &:hover {
      color: $primary_color;
    }

    font-size: 15px;
    line-height: 1.5;
    @include desktop {
      font-size: 16px;
      line-height: 1.6;
    }

    //&:hover {
    //  color: $primary_color;
    //}
  }

  .info {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .username {
      margin-right: 10px;
      color: $user_color;
      font-size: 13px;
      @include desktop {
        font-size: 13px;
      }
    }

    .time {
      cursor: pointer;
      color: $time_color;
      font-size: 12px;
      @include desktop {
        font-size: 13px;
      }
    }

  }

  .content {
    display: flex;
    flex-direction: row;
    margin-top: 10px;

    .content-left {
      flex: 1;
      width: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .desc {
        $line_num: 5;
        color: $desc_color;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: $line_num;
        overflow: hidden;
        width: 100%;
        white-space: pre-wrap !important;
        word-wrap: break-word;

        font-size: 14px;
        line-height: 1.5;
        max-height: calc(1.5 * #{$line_num} * 14px);
        @include desktop {
          font-size: 15px;
          line-height: 1.8;
          max-height: calc(1.8 * #{$line_num} * 16px);
        }
      }
    }

    .cover {
      object-fit: cover;
      height: 130px;
      width: 100px;
      margin-left: 15px;
      @include desktop {
        height: 170px;
        width: 130px;
        margin-left: 20px;
      }
    }
  }

  .content-info {
    margin-top: 10px;
    display: flex;
    align-items: center;
    //justify-content: space-between;
    user-select: none;
    //margin-right: 115px;
    //@include desktop {
    //  margin-right: 150px;
    //}

    .button {
      margin-left: 5px;
      font-size: 12px;
      border-radius: 2px;
      padding: 5px 8px;
      @include desktop {
        padding: 5px 12px;
        font-size: 13px;
      }
    }
  }
}

.edit-wrap {
  position: fixed;
  inset: 0;
  background: #0005;
  z-index: 99;
}
