@import "../media-query";
@import "../variables";

.label-wrap {
  display: flex;
  align-items: center;
  border-radius: 2px;
  user-select: none;
  -webkit-tap-highlight-color: transparent; //解决移动端点击跳闪

  font-size: 12px;
  padding: 5px 8px;
  @include desktop {
    font-size: 13px;
    //padding: 5px 10px;
  }

  &:active {
    background-color: $bg_color;
  }

  .text {
    margin-left: 2px;
  }
}