@import "variables";
@import "media-query";

.upload {
  display: flex;
  flex-direction: row;
  @include content_width;
  @include fade;
  margin-top: 20px;

  .up-left {
    display: flex;
    flex-direction: column;
    background-color: $fg_color;
    border-radius: 5px;
    flex: 1 1 calc(17 / 24 * 100%);
    box-sizing: border-box;

    padding: 15px;
    @include desktop {
      padding: 20px;
    }

    .select-wrap {
      position: relative;
      display: flex;
      align-items: stretch;
      margin-bottom: 10px;
      border-radius: 5px;
      border: 1px solid $bg_color;
      padding: 5px;
      @include desktop {
        margin-bottom: 20px;
      }

      .type-item {
        flex: 1;
        cursor: pointer;
        user-select: none;
        color: $title_color;
        text-align: center;
        border-radius: 5px;
        -webkit-tap-highlight-color: transparent; //解决移动端点击跳闪
        transition: background-color .2s;

        font-size: 14px;
        padding: 8px 0;
        @include desktop {
          font-size: 16px;
          padding: 10px 0;
        }

        &.active {
          //background-color: $primary_color;
          color: #fff;
        }
      }

      select {
        margin-bottom: 0;
        width: 100%;
      }

      .arrow {
        position: absolute;
        right: 10px;
        width: 15px;
        height: 15px;
        //在选择框的最右侧中间显示小箭头图片（图片自己定义）
        background: url('../assets/write-hover-thro.png') no-repeat right center;
        background-size: 15px;
      }
    }


  }

  .up-right {
    box-sizing: border-box;
    @include tablet {
      //margin-left: 20px;
      flex: 1 1 calc(7 / 24 * 100%);
    }
  }

  input, textarea, select {
    border-radius: 5px;
    border: 1px solid $bg_color;
    padding: 12px;
    font-size: 16px;
    resize: none;
    color: $title_color;
    margin-bottom: 10px;
    @include desktop {
      margin-bottom: 20px;
    }
  }

  .desc-wrap {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    @include desktop {
      flex-direction: row;
    }

    textarea {
      flex: 1;
      align-self: stretch;
    }

    img {
      border-radius: 5px;
      margin-bottom: 10px;
      width: 150px;
      height: auto;
      object-fit: contain;
      @include desktop {
        width: 200px;
        margin-left: 20px;
        flex-direction: row;
      }
    }
  }

  .button {
    font-size: 13px;
    padding: 8px 25px;
    @include tablet {
      font-size: 16px;
      padding: 12px 50px;
      align-self: flex-start;
    }
  }
}


.dialog {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 20vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .5);
  z-index: 9998;

  .m-item {
    border: .5px solid #ddd;
    padding: 5px 10px;
    border-radius: 2px;
    font-size: 15px;

    &.active {
      background: $primary_color;
      border-color: $primary_color;
      color: #fff;
    }
  }
}
