@import "../variables";
@import "../media-query";

.item-type {
  display: flex;
  align-items: center;
  border-radius: 2px;
  user-select: none;

  font-size: 12px;
  padding: 5px 8px;
  @include desktop {
    font-size: 14px;
    padding: 5px 10px;
  }

  span {
    margin-left: 5px;
  }
}