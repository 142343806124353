@import "../variables";
@import "../media-query";

.header-wrap {
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  z-index: 99;
  background-color: $fg_color;
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 50px;
  @include desktop {
    height: 55px;
  }
}

.logo {
  display: none;
  @include desktop {
    display: block;
    position: absolute;
    left: 2%;
    cursor: pointer;
  }
}

.login-text, .user-text {
  //position: absolute;
  //right: 20px;
  color: $primary_color;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
  font-size: 13px;
  @include desktop {
    font-size: 18px;
  }
}

.user-text {
  max-width: 90px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: $user_color;
  @include tablet {
    max-width: 150px;
  }
}

.header {
  display: flex;
  align-items: center;
  border-radius: 5px;
  background: $fg_color;
  @include content_width;

  //padding-left: 15px;
  //@include desktop {
  //  padding-left: 20px;
  //}
  .header-left-wrap {
    flex: 1 1 calc(17 / 24 * 100%);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    position: relative;

    .nav-item {
      color: $title_color;
      display: inline-block;
      padding: 6px 10px;
      border-bottom: 2px solid transparent;
      @include tablet {
        padding: 8px 20px;
        margin-left: 10px;
      }

      &.active {
        color: $primary_color;
        font-weight: 500;
        border-bottom-color: $primary_color;
      }
    }
  }

  .header-left {
    flex: 1;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 5px;
    @include tablet {
      margin-right: 15px;
    }

    .icon {
      position: absolute;
      right: 15px;
      z-index: 100;
      cursor: pointer;
    }
  }

  .header-right {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: auto;
    @include tablet {
      flex: 1 1 calc(7 / 24 * 100%);
      box-sizing: border-box;
      margin-left: 20px;
      justify-content: flex-end;
    }
  }

  .button {
    height: 35px;
    width: 65px;
    font-size: 15px;
    @include tablet {
      height: 40px;
      width: 100px;
    }
    @include desktop {
      height: 45px;
      width: 120px;
      font-size: 18px;
    }
  }

  input {
    color: $title_color;
    flex: 1;
    background-color: transparent;
    border: 1px solid $bg_color;
    border-radius: 5px;
    width: 100px;
    @include desktop {
      width: auto;
    }

    &:focus {
      border-color: $primary_color;
    }

    &::placeholder {
      color: #c3c3c3;
    }

    font-size: 15px;
    //margin: 0 15px;
    padding: 10px 40px 10px 15px;
    @include desktop {
      font-size: 16px;
      //margin: 0 20px;
    }
  }
}

.up-section, .notice-section {
  //position: fixed;
  //z-index: 9;
  //bottom: 0;
  //left: 0;
  //right: 0;
  display: flex;
  margin-left: 15px;
  //justify-content: flex-end;
  //@include content_width;
  //pointer-events: none;

  .btn-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    //margin-bottom: 10px;
    background-color: $primary_color;
    box-shadow: 0 0 5px #0003;
    user-select: none;
    cursor: pointer;
    pointer-events: auto;
    -webkit-tap-highlight-color: transparent;
    @at-root {
      .notice-section {
        //margin-bottom: 50px;
        @include tablet {
          display: none;
        }

        .btn-wrap {
          width: 70px;
          background-color: #ec416f;
        }
      }
    }

    width: 110px;
    height: 40px;
    border-radius: 25px;
    @include tablet {
      //margin-bottom: $bottom_tab_height;
      //width: 140px;
      //height: 50px;
      border-radius: 35px;
    }

    &:active {
      background-color: $primary_color_dark;
    }

    span {
      color: #fff;
      font-size: 13px;
      margin-left: 5px;
      @include desktop {
        //font-size: 13px;
        //margin-left: 10px;
      }
    }
  }
}

.bottom-tab {
  position: fixed;
  z-index: 99;
  bottom: 0;
  left: 0;
  right: 0;
  height: $bottom_tab_height;
  background-color: $fg_color;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-top: 0.5px solid $bg_color;
  @include desktop {
    display: none;
  }

  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #333;
    font-size: 12px;
    flex: 1;
    cursor: pointer;
  }

  .icon {
    margin-bottom: 5px;
    //background-color: #0002;
  }
}
