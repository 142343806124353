@import "media-query";
@import "variables";

.detail {
  display: flex;
  align-items: start;
  @include content_width;
  padding: 10px 0;
  @include desktop {
    padding: 20px 0;
  }

  .detail-left {
    box-sizing: border-box;
    flex: 1 1 calc(17 / 24 * 100%);
    width: 0;
    background-color: $fg_color;
    border-radius: 5px;

    //padding:0 15px;
    @include tablet {
      //padding: 0 20px;
    }

    .detail-header {
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #fafafa;

      .delete, .edit {
        cursor: pointer;
        padding: 5px;
        color: #6A90FF;
        font-size: 14px;
        @include desktop {
          padding: 10px;
          font-size: 15px;
        }

        &:hover {
          text-decoration: underline;
        }
      }

      .delete {
        color: var(--toastify-color-error);
      }
    }
  }

  .detail-right {
    border-radius: 5px;
    overflow: hidden;
    //padding: 10px;
    position: sticky;
    display: none;
    @include tablet {
      top: 70px;
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      flex: 1 1 calc(7 / 24 * 100%);
      width: 0; //防止内容被文字撑开
      //min-height: 200px;
    }
    @include desktop {
      top: 75px;
    }

    .avatar {
      border-radius: 100px;
      width: 50px;
      height: 50px;
    }

  }

  .labels {
    display: flex;
    margin-top: 10px;
  }

  .user-wrap {
    display: flex;
    justify-content: space-between;

    .username {
      color: $user_color;
      font-size: 16px;
      @include desktop {
        font-size: 18px;
      }
    }

    .time {
      color: $time_color;
      font-size: 14px;
    }
  }

  .title {
    margin: 15px 0;
    color: $title_color;
    font-weight: 500;
    line-height: 1.4;
    word-break: break-all;

    font-size: 16px;
    @include desktop {
      font-size: 18px;
      line-height: 1.6;
    }
  }

  .desc {
    color: $desc_color;
    margin-top: 10px;
    width: 100%;
    white-space: pre-wrap !important;
    word-wrap: break-word;

    font-size: 15px;
    line-height: 1.4;
    @include desktop {
      font-size: 16px;
      line-height: 1.7;
    }
  }

  .cover {
    object-fit: cover;
    //margin-top: 10px;
    width: 100%;
    @include desktop {
      margin-top: 20px;
    }
  }
}