@import "media-query";
@import "variables";

.userList {
  display: flex;
  flex-direction: row;
  align-items: start;
  @include content_width;

  //padding: 10px 0;
  //@include desktop {
  //  padding: 20px 0;
  //}

  .user-left {
    flex: 1 1 calc(17 / 24 * 100%);
    width: 0;
    //background-color: $fg_color;
    //padding: 15px;
    box-sizing: border-box;

    .content {
      background-color: $fg_color;
      padding: 15px;
    }
  }

  .user-right {
    margin-left: 20px;
    flex: 1 1 calc(7 / 24 * 100%);
    width: 0; //防止内容被文字撑开
    display: none;
    @include desktop {
      display: flex;
    }
  }

  .user-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $bg_color;
    transition: all ease .2s;
    height: 65px;
    padding: 0 10px;
    @include desktop {
      height: 55px;
      padding: 0 15px;
    }

    &:hover {
      //box-shadow: inset 0  0 4px #0002;
      //border-color: transparent;
    }

    &:first-child {
      //border-top: 1px solid $bg_color !important;
    }

    &:last-child {
      border-bottom: none;
    }

    .id {
      width: 70px;
      text-align: center;
      color: #888;
      font-size: 12px;
      @include mobile {
        display: none;
      }
    }

    .name {
      flex: 1;
      text-align: center;
      color: $user_color;
      font-size: 14px;
      word-break: break-word;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      box-sizing: border-box;
      padding-right: 10px;
      max-width: 200px;
      @include mobile {
        text-align: left;
        max-width: 120px;
      }
    }

    .email {
      flex: 1;
      font-size: 13px;
      color: #888;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-break: break-word;
      overflow: hidden;
      @include mobile {
        font-size: 12px;
        max-width: 125px;
      }
    }

    .time, .refer {
      flex: 1;
      font-size: 13px;
      color: #999;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-break: break-word;
      overflow: hidden;
      @include mobile {
        font-size: 12px;
        max-width: 125px;
      }
    }

    .refer {
      flex: 1;
    }


    .reward {
      color: #f5b005;
      font-weight: 500;
      font-size: 13px;
      @include desktop {
        font-size: 15px;
      }
    }
  }
}
