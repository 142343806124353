@import "variables";
@import "media-query";

.top {
  display: flex;
  flex-direction: row;
  @include content_width;
  @include fade;
  margin-top: 20px;

  .top-left {
    display: flex;
    flex-direction: column;
    background-color: $fg_color;
    border-radius: 5px;
    flex: 1 1 calc(17 / 24 * 100%);
    box-sizing: border-box;

    padding: 0 15px 15px;
    @include desktop {
      padding: 0 20px 20px;
    }

    .top-item {
      margin-bottom: 5px;
      border: 1px solid $bg_color;
      line-height: 40px;
      height: 40px;
      display: flex;

      .order-num:focus, .resource-id:focus {
        border-bottom-color: $primary_color;
      }

      .order-num {
        color: #ff2d55;
        font-weight: 500;
        border: 1px solid #fff;
        border-right-color: $bg_color;
        display: inline-block;
        width: 40px;
        text-align: center;
        font-size: 15px
      }

      .resource-id {
        color: $user_color;
        display: inline-block;
        width: 90px;
        border: 1px solid #fff;
        border-right-color: $bg_color;
        text-align: center;
        font-size: 15px
      }

      .name {
        display: inline-block;
        color: $title_color;
        margin: 0 15px;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        white-space: normal !important;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        flex: 1;
        font-size: 15px;

        &:hover {
          color: $primary_color;
        }
      }

      .delete-icon {
        display: inline-block;
        border-left: 1px solid $bg_color;
        cursor: pointer;
        padding: 0 10px;
      }
    }
  }

  .top-right {
    box-sizing: border-box;
    @include tablet {
      margin-left: 20px;
      flex: 1 1 calc(7 / 24 * 100%);
    }
  }

  input::placeholder {
    color: #ccc !important;
  }

  .label-wrap {
    border-radius: 0;
    margin-left: 0 !important;
  }
}