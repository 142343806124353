@import "../media-query";
@import "../variables";

.button {
  cursor: pointer;
  border-radius: 5px;
  color: #fff;
  user-select: none;
  background-color: $primary_color;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-tap-highlight-color: transparent;

  font-size: 14px;
  //padding: 10px 20px;
  @include desktop {
    font-size: 16px;
    //padding: 10px 25px;
  }
}

.button[aria-disabled=true] {
  background-color: #b8c4c9;
}

.button[aria-disabled=false]:active {
  background-color: $primary_color_dark;
}