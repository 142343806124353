@import "../variables";
@import "../media-query";

.login {
  position: fixed;
  inset: 0;
  background-color: #0005;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  height: 100%;
  //pointer-events: auto;
  animation: fade-spin .2s ease;

  .login-type {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: $title_color;

    font-size: 15px;
    @include desktop {
      font-size: 18px;
      margin-bottom: 15px;
    }

    .check-login, .check-signup {
      padding: 5px 15px;
      cursor: pointer;
      user-select: none;
      border-bottom: 3px solid transparent;
      -webkit-tap-highlight-color: transparent;

      margin-bottom: 10px;
      @include desktop {
        margin-bottom: 15px;
      }

      &.active {
        border-color: $primary_color;
        color: $primary_color;
      }
    }

    .check-login {
      margin-right: 20px;
    }

    .check-signup {
    }
  }

  .content {
    background-color: $fg_color;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    animation: slide-in-bottom-spin .3s ease;

    width: 80%;
    padding: 12px 20px 20px 20px;
    @include tablet {
      width: 50%;
      max-width: 500px;
      padding: 20px 35px 35px 35px;
    }
    @include desktop {
      width: 30%;
      max-width: 500px;
      padding: 30px 50px 50px 50px;
    }
  }

  input {
    border: 1px solid $bg_color;
    border-radius: 5px;
    margin: 5px 0;
    color: $title_color;

    padding: 10px;
    font-size: 14px;
    @include desktop {
      padding: 15px;
      font-size: 16px;
    }
  }

  .button {
    padding: 12px 0;
    margin-top: 20px;
  }
}

@keyframes fade-spin {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slide-in-bottom-spin {
  0% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0);
  }
}