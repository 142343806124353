@import "variables";
@import "media-query";

.notice {
  display: flex;
  flex-direction: column;
  @include content_width;

  padding: 10px 0;
  @include desktop {
    padding: 20px 0;
  }

  .main {
    display: flex;
    flex-direction: row;
    align-items: start;

    .main-left {
      flex: 1 1 calc(17 / 24 * 100%);
    }

    .main-right {
      background-color: transparent;
      display: none;
      padding: 10px;
      box-sizing: border-box;
      border-radius: 5px;
      position: sticky;

      @include tablet {
        display: flex;
        flex-direction: column;
        top: 65px;
        position: sticky;
        width: 0; //防止内容被文字撑开
        margin-left: 20px;
        flex: 1 1 calc(7 / 24 * 100%);
      }
      @include desktop {
        top: 75px;
      }

      .icon {
        padding: 10px;
      }
    }
  }
}


